<template>
  <div>
    <apexchart
      type="bar"
      height="350"
      :options="options"
      :series="series">
    </apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'; // Correct import for Vue 3

export default {
  components: {
    apexchart: VueApexCharts, // Register the apexchart component
  },
  props: {
    barChart: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      options: {
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: false, // Hide the toolbar if not needed
          },
        },
        xaxis: {
          categories: this.barChart.date_format || [], // Set categories from the barChart prop
        },
        tooltip: {
          y: {
            formatter: (value) => `Order Amount: ${value}`, // Tooltip formatter
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top', // Show data labels on top of bars
            },
          },
        },
      },
      series: [{
        name: 'Order Amount',
        data: this.barChart.order_amount || [], // Set initial data from the barChart prop
      }],
    };
  },
  watch: {
    barChart: {
      immediate: true,
      handler(newChartData) {
        console.log('Updated Bar Chart Data:', newChartData);
        this.updateChartData(newChartData);
      },
    },
  },
  methods: {
    updateChartData(newChartData) {
      this.options.xaxis.categories = newChartData.date_format || [];
      this.series[0].data = newChartData.order_amount || [];
      console.log('Categories updated:', this.options.xaxis.categories);
      console.log('Order Amount data updated:', this.series[0].data);
    },
  },
};
</script>

<style scoped>
/* Add any custom styles here if needed */
</style>
