import { useQuery } from 'vue-query';
import apiAxios from '../apiAxios';

const fetchDashStats = async () => {
  const { data } = await apiAxios.get('/dashboard-stats');
  console.log("hhhh");
  console.log(data);
  return data;
};

const fetchLogs = async ({ search, perPage, page }) => {
  let URL = `/logs?page=${page}`;
  if (search) {
    URL += `&search=${search}`;
  }
  if (perPage) {
    URL += `&per_page=${perPage}`;
  }

  const { data } = await apiAxios.get(URL);
  return data;
};
const fetchSetting = async () => {
  const { data } = await apiAxios.get(`/setting`);
  return data;
};

const addSetting = async (body) => {
  const { data } = await apiAxios.post('/setting', body);
  return data;
};

// queries
const useDashStatsQuery = () => useQuery(['dashboard-stats'], fetchDashStats);
console.log(useDashStatsQuery);
const useLogsInfinQuery = ({ search, perPage, page }) =>
  useQuery(['logs', search, perPage, page], () => fetchLogs({ search, perPage, page }));

const useSettingQuery = () => useQuery(['settings'], fetchSetting);

export default {
  fetchDashStats,
  // queries
  useDashStatsQuery,
  useLogsInfinQuery,
  useSettingQuery,
  addSetting,
};
