/* eslint-disable no-unused-vars */
import { createRouter, createWebHistory } from 'vue-router';
import { authGurd, pagesGurd } from '@/middleware/gurds';

import HomeRouter from '@/views/HomeRouter.vue';
import HomeView from '@/views/HomeView.vue';

// dashboard
import DashboardRouter from '@/views/dashboard/DashboardRouter.vue';
import DashboardView from '@/views/dashboard/DashboardView.vue';

// Auth
import AuthRouter from '@/views/auth/AuthRouter.vue';
import LoginView from '@/views/auth/LoginView.vue';

const routes = [
  {
    path: '/',
    component: HomeRouter,
    beforeEnter: (to, from, next) => {
      // gurds
      pagesGurd(next);
    },
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
        redirect: '/dashboard',
      },
    ],
  },

  {
    path: '/dashboard',
    component: DashboardRouter,
    beforeEnter: (to, from, next) => {
      // gurds
      pagesGurd(next);
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: DashboardView,
      },
      {
        path: 'admins',
        name: 'Admins',
        component: () => import('@/views/dashboard/users/UsersView.vue'),
      },
      {
        path: 'myprofile',
        name: 'User Profile',
        component: () => import('@/views/dashboard/users/ProfileView.vue'),
      },
      {
        path: 'roles',
        name: 'roles',
        component: () => import('@/views/dashboard/roles/RoleView.vue'),
      },
      {
        path: 'roles/add_role',
        name: 'add Role',
        component: () => import('@/views/dashboard/roles/AddRoleView.vue'),
      },
      {
        path: 'roles/:roleId/edit',
        name: 'Edit Role',
        component: () => import('@/views/dashboard/roles/EditRoleView.vue'),
      },
      {
        path: 'logs',
        name: 'All Logs',
        component: () => import('@/views/dashboard/logs/LogView.vue'),
      }, {
        path: 'category',
        name: 'Category',
        component: () => import('@/views/dashboard/category/CategoryView.vue'),
      }, {
        path: 'testimonial',
        name: 'Testimonial',
        component: () => import('@/views/dashboard/testimonial/TestimonialView.vue'),
      }, {
        path: 'faqs',
        name: 'FAQ',
        component: () => import('@/views/dashboard/faq/FaqView.vue'),
      }, {
        path: 'blogs',
        name: 'Blog',
        component: () => import('@/views/dashboard/blogs/BlogView.vue'),
      },
      {
        path: 'blogs/add_blog',
        name: 'add blog',
        component: () => import('@/views/dashboard/blogs/AddBlogView.vue'),
      },
      {
        path: 'blogs/:blogId/edit',
        name: 'Edit Blog',
        component: () => import('@/views/dashboard/blogs/EditBlogView.vue'),
      },
      {
        path: 'partners',
        name: 'Partners',
        component: () => import('@/views/dashboard/partners/PartnerView.vue'),
      }, {
        path: 'logs',
        name: 'Logs',
        component: () => import('@/views/dashboard/logs/LogView.vue'),
      }, {
        path: 'setting',
        name: 'Setting',
        component: () => import('@/views/dashboard/settings/SettingView.vue'),
      }, {
        path: 'books',
        name: 'Books',
        component: () => import('@/views/dashboard/books/BookView.vue'),
      },
      {
        path: 'books/add_book',
        name: 'add Book',
        component: () => import('@/views/dashboard/books/AddBookView.vue'),
      },
      {
        path: 'books/:bookId/edit',
        name: 'Edit Book',
        component: () => import('@/views/dashboard/books/EditBookView.vue'),
      },
      {
        path: 'university',
        name: 'University',
        component: () => import('@/views/dashboard/university/UniversityView.vue'),
      },
      {
        path: 'about_us',
        name: 'About us',
        component: () => import('@/views/dashboard/about_us/AboutView.vue'),
      },
      {
        path: 'library_blocks',
        name: 'Library Blocks',
        component: () => import('@/views/dashboard/library_blocks/BlockView.vue'),
      },
      {
        path: 'smart_reader',
        name: 'Smart Reader',
        component: () => import('@/views/dashboard/smart_reader/ReaderView.vue'),
      },
      {
        path: 'ai',
        name: 'AI',
        component: () => import('@/views/dashboard/ai/AIView.vue'),
      },
      {
        path: 'educational_institutions',
        name: 'Educational Institutions',
        component: () => import('@/views/dashboard/educational_institutions/EducationalView.vue'),
      },
      {
        path: 'mail_list',
        name: 'Mail List',
        component: () => import('@/views/dashboard/mail_list/MailListView.vue'),
      },
      {
        path: 'inbox',
        name: 'Inbox',
        component: () => import('@/views/dashboard/inbox/InboxView.vue'),
      },
      {
        path: 'slider',
        name: 'Slider',
        component: () => import('@/views/dashboard/slider/SliderView.vue'),
      },
      {
        path: 'educational_institutions',
        name: 'Educational Institutions',
        component: () => import('@/views/dashboard/educational_institutions/EducationalView.vue'),
      },
      {
        path: 'clients',
        name: 'Clients',
        component: () => import('@/views/dashboard/clients/ClientView.vue'),
      },
      {
        path: 'clients/:clientId/profile',
        name: 'Client Profile',
        component: () => import('@/views/dashboard/clients/ProfileView.vue'),
      },
      {
        path: 'authors',
        name: 'Authors',
        component: () => import('@/views/dashboard/authors/AuthorView.vue'),
      },
      {
        path: 'publishers',
        name: 'Publishers',
        component: () => import('@/views/dashboard/publishers/PublisherView.vue'),
      },
    ],
  },

  {
    path: '/',
    component: AuthRouter,
    beforeEnter: (to, from, next) => {
      // gurds
      authGurd(next);
    },
    children: [
      {
        path: 'login',
        name: 'login',
        component: LoginView,
      },
    ],
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
