import { AUTHORS } from '../types';

export default {
  state: {
    authorQuery: null,
  },
  getters: {},
  mutations: {
    [AUTHORS.SET_AUTHORS_QUERY](state, payload) {
      state.authorQuery = payload;
    },

  },
  actions: {},
};
