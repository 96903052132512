<template>
  <div>
    <apexchart
      type="donut"
      height="350"
      :options="options"
      :series="series">
    </apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'; // Import VueApexCharts for Vue 3

export default {
  components: {
    apexchart: VueApexCharts, // Register the apexchart component
  },
  props: {
    topFiveTitle: {
      type: Array,
      default: () => [],
    },
    topSales: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: {
        chart: {
          id: 'donut-chart',
          toolbar: {
            show: false, // Hide the toolbar if not needed
          },
        },
        labels: this.topFiveTitle, // Set labels from the prop
        tooltip: {
          y: {
            formatter: (value) => `Donation Amount: ${value}`, // Format the tooltip
          },
        },
        dataLabels: {
          enabled: true, // Enable data labels
        },
      },
      series: this.topSales, // Set initial data from the prop
    };
  },
  watch: {
    topFiveTitle: {
      immediate: true,
      handler(newTitles) {
        console.log(newTitles);
        this.options.labels = newTitles; // Update labels when titles change
      },
    },
    topSales: {
      immediate: true,
      handler(newSales) {
        this.series = newSales; // Update series data when sales change
      },
    },
  },
};
</script>

<style scoped>
/* Add any custom styles here if needed */
</style>
