import { PUBLISHERS } from '../types';

export default {
  state: {
    publisherQuery: null,
  },
  getters: {},
  mutations: {
    [PUBLISHERS.SET_PUBLISHERS_QUERY](state, payload) {
      state.publisherQuery = payload;
    },

  },
  actions: {},
};
